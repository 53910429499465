// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


window.addAddress = function() {
  document.querySelector("#add_address").click();
}

window.addFormField = function() {
  select = document.querySelector("#set_form_field_type")
  document.querySelector("#form_field_type").value =  select.options[select.selectedIndex].value;
  document.querySelector("#add_form_field").click();
}

window.addFormTemplate = function() {
  select = document.querySelector("#build_form_template");
  value = select.options[select.selectedIndex].value;
  document.querySelector("#secondary_form_template_id").value = value;
  if (value != "") {
    document.querySelector("#form_template_id").value = value;
    document.querySelector("#add_form_template").click();
  }
}

window.disableSubform = function(ele) {
  name = ele.name;
  selector = name.split("[_destroy]")[0];
  inputs = document.querySelectorAll("[name^='" + selector + "']");
  inputs.forEach(function(input) {
    if (input.name != ele.name && input.name != selector + "[id]") {
      input.disabled = ele.checked;
    }
  });
}